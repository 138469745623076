
<template>
  <div id="page-user-edit">
    <div class="vx-row">
      <div class="vx-col lg:w-2/3 w-full">
        <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
          <span>User record with id: {{ $route.params.userId }} not found. </span>
          <span>
            <span>Check </span><span @click="$router.push('/users').catch(() => {})" class="text-inherit underline">All Users</span>
          </span>
        </vs-alert>
        <vx-card v-if="!isLoading" >
          <div slot="no-body" class="tabs-container px-6 pt-6">
            <vs-tabs class="tab-action-btn-fill-conatiner">
              <vs-tab :label="!isSmallerScreen ? 'Account' : ''" icon-pack="feather" icon="icon-user">
                <div class="tab-text">
                  <user-edit-tab-account class="mt-4" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'Settings' : ''" icon-pack="feather" icon="icon-settings">
                <div class="tab-text">
                  <user-edit-tab-settings class="mt-4" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'Password' : ''" icon-pack="feather" icon="icon-lock">
                <div class="tab-text">
                  <user-edit-tab-password class="mt-4" />
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabSettings from "./UserEditTabSettings.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  components: {
    UserEditTabAccount,
    UserEditTabSettings,
    UserEditTabPassword,
  },
  data() {
    return {
      isLoading: true,
      user_not_found: false,
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  created() {
    if(!this.$store.getters['userList/totalRoles']){
      this.$store.dispatch("userList/fetchRoles")
    }
    this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
    .then((response) => {
      if(response.data.success){
        this.isLoading = false
      }else{
        this.user_not_found = true
        this.alertError(response.data.error)
      }
    }).catch(err => { console.error(err) })

    if(!this.$store.state.countries.length){
      this.$store.dispatch('fetchCountries')
    }
    if(!this.$store.state.languages.length){
      this.$store.dispatch('fetchLanguages')
    }
    if(!this.$store.state.skills.length){
      this.$store.dispatch('fetchSkills')
    }
  }
}

</script>
